
              @import "@/assets/css/variables.scss";
            


























































// @import '@/assets/css/components/home/pendingAction.scss';
.alert_description {
  display: flex;
  align-items: center;
  .description_btn {
    color: #007aff;
    text-decoration: underline;
  }
}
